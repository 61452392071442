/* global $ */
'use strict';

import './help_modal.scss';

/* Load Javascript for Help Modal Module */
(function HelpModalModule(){

	var $help_modals = rp$(".help-modal-wrap");

	rp$.each($help_modals, function HelpModal_Iterator(idx, help_modal_ele) {
		var $help_modal = rp$(help_modal_ele).find('.help-modal');
		var $heading;
		var $help_button;

		// Target child button
		$help_button = rp$(help_modal_ele).find(".help-button");

		// Open the sibling modal only
		$help_button.on('click', function openHelpModalFunction(){
			rp$(this).siblings(".help-modal").modal();
			rp$(this).siblings(".help-modal").find(".contact-section").addClass("help-section-expand"); // Expand contact on modal pop up
		});

		// Mobile Slider Styles
		$help_modal.on('click', '.section-heading', function SectionHeadingClick() {
			$heading = rp$(this);

			if(!$heading.is('.help-section-expand')) {
				$help_modal.find('.section-heading').removeClass('help-section-expand');
				$heading.addClass('help-section-expand');
			}
			else if ($heading.is('.help-section-expand')) {
				$help_modal.find('.section-heading').removeClass('help-section-expand');
			}

		});
	});
})();
